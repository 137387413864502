import React from 'react'
import ComposedTitle from '../../../Components/ComposedTitle'
import { motion } from "framer-motion";
import { FaShippingFast } from 'react-icons/fa';
import { BiShoppingBag, BiSupport } from 'react-icons/bi';
import { GiAchievement } from 'react-icons/gi';

export default function WhyUs() {
  const Sections = [
    {
      icon: <BiSupport />,
      label: "Assistance 24/7",
    },
    {
      icon: <GiAchievement />,
      label: "haute qualité",
    },
    {
      icon: <BiShoppingBag />,
      label: "UTILISation FACILE",
    },
    
    {
      icon: <FaShippingFast />,
      label: "Expédition rapide",
    },
  ]
  return (
    <div className='bg-packs-section border-b-8 border-C2'>
      <div className='2xl:max-w-7xl max-w-5xl m-auto py-24'>
        <ComposedTitle title={"POURQUOI CHOISIR"} subTitle={"DOCUVERSE"} />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 lg:gap-4 gap-10 mt-6 items-center justify-center w-full justify-items-center self-center p-4 lg:p-0">
          {Sections.map((sec, index) => (
            <motion.div whileHover={{ scale: 1.1, zIndex: 10 }} key={index} className=" h-fit w-80   flex flex-col justify-center items-center gap-2
             " >              
            <div className='flex justify-center items-center lg:text-8xl text-6xl relative border-2 border-black lg:h-52 lg:w-52 w-40 h-40 text-black hover:bg-black  hover:text-white-plus hover:duration-500 duration-1000'>
            {sec.icon}
            <svg width="50" height="49" className='absolute bottom-0 right-0' viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40 39.5L50 0V49H0L40 39.5Z" fill="black"/>
            </svg>

            <svg width="33" height="32" className='absolute top-0 left-0'  viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 9.5L0 32L0 0L33 0L9 9.5Z" fill="black"/>
              </svg>
            </div>
              <p class="text-center lg:text-2xl text-lg  lg:font-black font-semibold font-monta flex justify-center items-center uppercase"> {sec.label}</p>
              </motion.div>
          ))}
        </div>
 


      </div>
    </div>
  )
}

