import * as React from "react";
import { motion } from "framer-motion";

const variants = {
    open: {
        opacity: 1,
        display: "flex",

    },
    closed: {
        opacity: 0,
        display: "none",

    }
};


export const MenuItem = (prop) => {
    return (
        <li className="w-full" key={prop.key}>
            <motion.a
                href={prop.i.url}
                variants={variants}
                whileHover={{ scale: 1.05, translateX: 120, rotate: 3 }}
                whileTap={{ scale: 0.9 }}
                className="text-white-plus font-monta uppercase font-black lg:text-9xl text-3xl   w-full">
                {prop.i.title}
            </motion.a>
        </li>
    );
};

