import React, { useEffect, useState } from 'react'
import ComposedTitle from '../../../Components/ComposedTitle'
import { motion } from "framer-motion";

export default function PacksSection() {
    const [packs, setPacks] = useState(null);
    const loadProduct = async () => {
      try {
        const response = await fetch('https://docuverse.tn/api/products-by-categorie-id/3', {
          method: 'GET', // Specify the HTTP method
          headers: {
            'Content-Type': 'application/json', 
          }});
        const data = await response.json();
        setPacks(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
 
    useEffect(() => {
        loadProduct();
      }, [])
      if (!packs) {
        return <div>...</div>;
      }
    
    
  return (
    <div className='bg-packs-section'>
        <div className='2xl:max-w-7xl max-w-5xl m-auto py-24'>
          <ComposedTitle title={"DERNIERS Packs"} subTitle={"DE LA COLLECTION DOCUVERSE"} />
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
                {packs.map((prod, index) => (
                  <motion.a href={`/products/${prod.products_id}`} whileHover={{scale:1.1, zIndex:10}} key={index} className="bg-white p-4 shadow rounded-lg w-fit">
                    <img
                      src={`https://docuverse.tn/public/SiteImages/products/${prod.main_pic_link}`}
                      alt={prod.name}
                      className="w-[200px] h-[250px] object-cover"
                    />
                    <h3 className="text-lg font-semibold mt-2">{prod.name}</h3>
                    <p className="text-gray-600">{prod.price} TND</p>
                    <p className={`text-${prod.stockStatus === "En stock" ? "green" : "red"}-600`}>{prod.stockStatus}</p>
                  </motion.a>
                ))}
            </div>
            <div className='flex justify-center items-center mt-8 flex-col gap-8'> 
                <div className='flex flex-col items-end'>
                    <h1 className='main-title text-black-plus font-black lg:text-5xl text-2xl font-monta lg:text-start text-center'>VOUS CHERCHEZ PLUS DE PRODUITS ?</h1>
                    <svg className='lg:w-[635px] w-56 h-auto lg:mx-none mx-auto'  viewBox="0 0 635 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M633.157 2.35535C444.012 2.35535 255.093 10.3182 65.9829 11.6658C38.0439 11.8649 31.2508 11.8414 8.59793 11.8414C-26.8481 11.8414 79.49 11.7444 114.936 11.6072C258.146 11.0531 401.368 10.1231 544.562 7.80106C546.846 7.76402 553.697 7.60712 551.413 7.6254C544.249 7.68271 537.086 7.78017 529.923 7.91818C491.006 8.66802 470.868 9.30925 428.972 10.7289C401.267 11.6677 284.337 15.9653 259.862 16.8187C233.572 17.7354 207.282 18.6715 180.987 19.4537C164.633 19.9402 148.277 20.4019 131.917 20.6249C125.359 20.7142 118.416 22.6051 112.242 20.3906C107.728 18.7713 121.748 19.0611 126.53 18.6925C140.999 17.5772 155.487 16.7106 169.979 15.9404C250.886 11.6397 331.879 8.75851 412.869 6.57139C442.957 5.75888 479.812 4.8986 511.009 4.75615C547.372 4.59011 514.163 5.78506 509.838 6.57139" stroke="#ED7924" stroke-width="3" stroke-linecap="round"/>
                    </svg>
                </div>
            <div className='flex justify-center items-end relative mt-8 overflow-hidden'>
                <svg className='md:flex hidden absolute -left-32 -top-8' width="99" height="77" viewBox="0 0 99 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2.33594C22.0651 26.7332 49.0854 43.1476 76.9166 57.1745C80.5969 59.0294 80.8729 59.172 83.9041 60.9779C84.5209 61.3453 85.9384 62.4034 85.9384 61.6855C85.9384 55.3715 79.4251 46.4734 76.209 41.4306C75.5523 40.4009 72.8156 36.3879 71.6981 35.1507C71.3205 34.7326 71.6258 36.326 71.875 36.8312C77.7751 48.7908 86.4559 58.4023 94.3411 68.9825C95.5259 70.5723 98.531 73.1608 94.9161 74.2453C92.1432 75.0771 81.7985 74.776 80.4104 74.776C61.7033 74.776 42.9963 74.776 24.2892 74.776" stroke="#ED7924" stroke-width="3" stroke-linecap="round"/>
                </svg>
                <motion.a href='/products'  whileHover={{ scale: 1.2, translateX: 5, rotate: 3 }}
                whileTap={{ scale: 0.9 }} className="bg-C1 text-white duration-300 overflow-hidden  mx-auto text-center text-2xl py-4 px-12 transition-all hover:bg-C1/85 active:scale-95 rounded-2xl font-monta ">Plus de produits</motion.a>
                <svg className='md:flex hidden absolute -right-44 -top-12' width="152" height="90" viewBox="0 0 152 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M149.948 1.55969C152 17.5999 140.736 29.5552 127.88 37.6913C115.605 45.4596 101.769 50.3409 88.0334 54.8062C66.8778 61.6838 45.2824 67.4442 23.6423 72.5845C21.3357 73.1324 18.7132 75.0875 16.6548 73.9113C15.6587 73.3421 17.1548 71.9411 17.5393 71.6116C22.3763 67.4656 27.456 63.175 32.9737 59.9805C37.6735 57.2596 42.5983 55.1002 47.6121 53.0372C48.1309 52.8238 52.2266 51.2469 53.2728 50.6491C53.931 50.273 51.7682 50.9053 51.0616 51.1798C45.2738 53.4283 39.6667 56.2311 34.0793 58.9191C23.7279 63.899 12.3696 68.43 2.90091 75.0169C-3.48831 79.4616 15.1169 83.9674 17.2739 84.5252C28.7871 87.5027 40.2465 87.5325 52.0345 87.5325" stroke="#ED7924" stroke-width="3" stroke-linecap="round"/>
                </svg>
            </div>
            </div>
            
        </div>
    </div>
    )
}
