import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { LiquidNav } from "../liquid-nav";
import LinkBox from "../../link-box/LinkBox"
import RotatedBox from "../../rotated-box/RotatedBox";
import { CarouselCustomNavigation } from "../../Carousel";
const ShuffleHero = ({ home = 1, title, subTitle }) => {
 
  
  return (home) ? (
    <div className=" overflow-hidden mb-8">
      <div className="hero-background">
        <LiquidNav />
        <section className="w-full px-8 py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-8  mx-auto ">
          <div className="lg:px-24">
            <h3 className="xl:text-6xl lg:text-4xl text-3xl font-black font-monta text-text-white-plus">
            En Prépa, prenez de l’Avance,
            Avec DOCUVERSE, faites la différence. </h3>
            <p class="text-base font-monta text-text-white-plus my-4 md:my-6">Bienvenue chez DOCUVERSE, votre allié pour réussir en classes préparatoires ! Chaque seconde compte ici. Profitez de notre sélection exclusive de livres pour optimiser vos révisions et vous préparer aux concours. Rejoignez notre communauté dynamique et prenez une longueur d’avance sur votre succès !</p>
            <div className="gap-3 flex">
              <a href="/products" className="border-text-white-plus border-[1px] flex justify-around items-center mt-4 gap-6 text-text-white-plus  py-2 px-4 transition-all hover:bg-white-plus/30 duration-300 active:scale-95 rounded-2xl font-monta ">
              Consultez notre boutique
                <FaArrowRight />
              </a>
            </div>
          </div>

          <div className="w-full lg:flex-row flex-col  h-[400px] gap-2 justify-between items-center lg:flex hidden">
          
              <motion.div
            whileHover={{scale: 1.2, zIndex:10, rotate:2}} className="w-[350px] relative h-full bg-C1 border-white-plus border-2   ">
            <img src="./4.jpg" alt=" " className="w-full h-full object-cover" />
            </motion.div>
            <motion.div
            whileHover={{scale: 1.2, zIndex:10, rotate:2}} className="w-[350px] relative h-full bg-C1 border-white-plus border-2   ">
            <img src="./5.jpg" alt=" " className="w-full h-full object-cover" />
            </motion.div>
            <motion.div
            whileHover={{scale: 1.2, zIndex:10, rotate:2}} className="w-[350px] relative h-full bg-C1 border-white-plus border-2   ">
            <img src="./6.jpg" alt=" " className="w-full h-full object-cover" />
            </motion.div>
          </div>
        </section>
      </div>
      <div className="-mt-8 z-10 h-40">
        <RotatedBox />
      </div>
    </div>
  ) :
    <div className="hero-background">
      <LiquidNav />
      <div className="flex justify-center items-center flex-col p-24 pt-8 gap-4">
        <h1 className="font-monta font-black text-text-white-plus text-5xl uppercase">{title}</h1>
        <h1 className="font-monta font-medium text-text-white-plus text-3xl  uppercase">{subTitle}</h1>
      </div>
    </div>
    ;
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

const squareData = [
  {
    id: 1,
    src: "./background.png",
  },
  {
    id: 2,
    src: "./background.png",
  },
  {
    id: 3,
    src: "./background.png",
  },
  {
    id: 4,
    src: "./background.png",
  },
  {
    id: 5,
    src: "./background.png",
  },
  {
    id: 6,
    src: "./background.png",
  },
  {
    id: 7,
    src: "./background.png",
  },
  {
    id: 8,
    src: "./background.png",
  },
  {
    id: 9,
    src: "./background.png",
  },
  {
    id: 10,
    src: "./background.png",
  }
];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "ease" }}
      className="w-full h-full"
      style={{
        backgroundImage: `url(${sq.src})`,
        backgroundSize: "cover",
      }}
    ></motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="grid lg:grid-cols-4 grid-cols-2 grid-rows-1 lg:h-[450px] h-[300px] gap-1">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default ShuffleHero;