import React, { useContext, useEffect, useState } from 'react'
import { DragCloseDrawer } from '../../Components/DrawerCloser/DrowerCloser';
import { BiShoppingBag } from 'react-icons/bi';
import { Button, Dialog, DialogFooter, DialogHeader, Option, Select } from '@material-tailwind/react';
import { motion } from 'framer-motion';
import { ShoppingCartContext } from '../LayoutMain';
import { Popup } from '../../utils/utils';



export default function ShoppingCart() {
  const shoppingCart = useContext(ShoppingCartContext);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [secondPhoneNumber, setSecondPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [address, setAddress] = useState('');
  const [governorates, setGovernorates] = useState([]);
  const [governorate, setGovernorate] = useState({});
  const [delegations, setDelegations] = useState([]);
  const [delegationsFiltred, setDelegationsFiltred] = useState([]);
  const [delegationsStatus, setDelegationsStatus] = useState(true);
  const [delegation, setDelegation] = useState(true);
  const [sectorsStatus, setSectorsStatus] = useState(true);
  const [sectors, setSectors] = useState([]);
  const [sector, setSector] = useState([]);
  const [sectorsFiltred, setSectorsFiltred] = useState([]);
  const [status, setStatus] = useState(null);
  const [popup, setPopup] = useState(false);
  const handleAdd = (handleItem, id)=>{
      if(handleItem==='governorate'){  
        if(Number.parseInt(id)===-1){
        setDelegationsStatus(true)
        setSectorsStatus(true)
        return 0;
        }
        setDelegationsStatus(false)
        setDelegationsFiltred(delegations.filter(delegation=> Number.parseInt(delegation.governorate_id) === Number.parseInt(id)))
      }else if(handleItem==='delegation'){
        if(Number.parseInt(id)===-1){
          setSectorsStatus(true)
          return 0;
          }
        setSectorsStatus(false)
        setSectorsFiltred(sectors.filter(sector=> Number.parseInt(sector.delegation_id) === Number.parseInt(id)))
      }
  }

  const handleSetGovernorate = (governorate)=>{
    if(Number.parseInt(governorate)!==-1){
      setGovernorate(governorates.filter(gov=>Number.parseInt(gov.governorates_id)===Number.parseInt(governorate))[0])    
    }else{
      setGovernorate({})
    }
  }
  const handleSetDelegation = (delegation)=>{
    if(Number.parseInt(delegation)!==-1){
    setDelegation(delegations.filter(del=>Number.parseInt(del.delegations_id)===Number.parseInt(delegation))[0])
    }else{
      setDelegation({})
    }
  }
  const handleSetSector = (sector)=>{
    if(Number.parseInt(sector)!==-1){
    setSector(sectors.filter(sec=>Number.parseInt(sec.sectors_id)===Number.parseInt(sector))[0])    
    }else{
      setSector({})
    }
  }


  const loadFromAddressNeeds = async () => {
    try {
      const response = await fetch('https://docuverse.tn/api/address-needs', {
        method: 'GET', // Specify the HTTP method
        headers: {
          'Content-Type': 'application/json', 
        }});
      const data = await response.json();
      setGovernorates(data.governorates);
      setDelegations(data.delegations);
      setSectors(data.sectors);
     } catch (error) {
      console.error('Error fetching products:', error);
    }
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Retrieve data from local storage
    let shoppingCartHolder = localStorage.getItem('shoppingCart')
    shoppingCartHolder =(shoppingCartHolder != null && shoppingCartHolder !== undefined) ? JSON.parse(shoppingCartHolder) : []

    // Gather form data
    const formData = {
      fullName,
      phoneNumber,
      secondPhoneNumber,
      emailAddress,
      address,
      governorate,
      delegation,
      sector,
      'shoppingCart':shoppingCartHolder,
    };

    // Send the data to the server
    try {
      const response = await fetch('https://docuverse.tn/api/place-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      shoppingCart.toggleCheckout()
      shoppingCart.toggleShoppingCart()
      console.log('Order placed successfully:', data);
      setStatus('DONE');
      setPopup(true);
      localStorage.setItem('shoppingCart', "[]")
      // Handle success (e.g., show success message, reset form, etc.)
    } catch (error) {
      shoppingCart.toggleCheckout()
      shoppingCart.toggleShoppingCart()
      setStatus('FAILED');
      setPopup(true);
      console.error('Error placing order:', error);
      // Handle error (e.g., show error message)
    }
  };


  useEffect(() => {
    shoppingCart.loadCart();
    loadFromAddressNeeds();
  }, [])




  return (
    <div>

        {
        (status==="DONE") ?
        <Dialog
        open={popup}
        size={"xs"}
      >
        <DialogHeader>  Order Has Been Placed Successfully, Thanks !</DialogHeader>

        <DialogFooter>
          <Button
            variant="text"
            onClick={() => setPopup(false)}
            className=" mr-1 bg-C1 text-white duration-300   py-2 px-4 transition-all hover:bg-C1/85 active:scale-95 rounded-2xl font-monta "
          >
            <span>Okey</span>
          </Button>
 
        </DialogFooter>
      </Dialog>
        
        :      <Dialog
        open={popup}
        size={"xs"}
      >
        <DialogHeader>  <span className="text-red font-bold">FAILED OPERATION !</span></DialogHeader>

        <DialogFooter>
          <Button
            variant="text"
            onClick={() => setPopup(false)}
            className=" mr-1 bg-C1 text-white duration-300   py-2 px-4 transition-all hover:bg-C1/85 active:scale-95 rounded-2xl font-monta "
          >
            <span>Okey</span>
          </Button>
 
        </DialogFooter>
      </Dialog>
        }



      <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 4 }} className='fixed bottom-2 right-2 w-20  h-20 bg-C1  text-white-plus rounded-full p-4 cursor-pointer' onClick={() => { shoppingCart.toggleShoppingCart() }}>
        <BiShoppingBag className='text-5xl' />
      </motion.div>
      <DragCloseDrawer open={shoppingCart.openCart} setOpen={shoppingCart.setOpenCart}>
        <div className="mx-auto  lg:max-w-screen-lg xl:max-w-screen-xl space-y-4 overflow-hidden cursor-pointer-impo">
          <h2 className="text-4xl font-bold   text-black-plus uppercase ">
          Panier

          </h2>
          <section className="  antialiased  ">
            <div className="mx-auto   px-4 2xl:px-0">

              <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8">
                <div className="mx-auto lg:w-10/12 w-full flex-none ">
                  <div className="space-y-6">
                    {(shoppingCart.getCartLength() === 0) ? "Ajouter des produits" : shoppingCart.getCartItems().map((item, index) => (itemUI(item, shoppingCart.addProductQte, shoppingCart.decProductQte, shoppingCart.removeProductFromCart, index)))}
                  </div>
                </div>

                <div className="mx-auto mt-6 lg:w-1/6 flex-1 space-y-6 lg:mt-0 w-full">
                  <div className="space-y-4 rounded-lg border border-gray-300   bg-white-plus p-4 shadow-sm sm:p-6">

                    <div className="space-y-4">
                    <dt className="text-base font-bold  text-black-plus"> {shoppingCart.calcCartSum() > 250 ? <del>Livraison: 7dt</del> : <span>Livraison: 7dt</span>} </dt>

                      <dl className="flex items-center justify-between gap-4 border-t border-gray-600 pt-2 dark:border-gray-700">
                        <dt className="text-base font-bold  text-black-plus  ">Total</dt>
                        <dd className="text-base font-bold  text-black-plus  ">{shoppingCart.calcCartSum()} TND</dd>
                      </dl>
                    </div>

                    <div onClick={shoppingCart.toggleCheckout} className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium  text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 select-none cursor-pointer">Checkout</div>

                    <div className="flex items-center justify-center gap-2">
                      <span className="text-sm font-normal text-gray-500 dark:text-gray-400"> or </span>
                      <a href="/products" title="" className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline dark:text-primary-500">
                      Continuer les achats
                        <svg className="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </DragCloseDrawer>



      <DragCloseDrawer open={shoppingCart.openCheckout} setOpen={shoppingCart.setOpenCheckout}>
        <div className="mx-auto  lg:max-w-screen-lg xl:max-w-screen-xl space-y-4 overflow-hidden cursor-pointer-impo">
          <h2 className="text-4xl font-bold   text-black-plus uppercase ">
            Checkout

          </h2>
          <section className="w-full ">

            <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12 flex-col justify-end  w-full">
            <form onSubmit={handleSubmit} className="w-full rounded-lg border border-gray-400 bg-white-plus p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
      <div className="mb-6 grid grid-cols-2 gap-4">
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="full_name" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Nom et prénom*</label>
          <input
            type="text"
            id="full_name"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
            placeholder="Flen Ben Foulen"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="phone_number" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Numéro de téléphone*</label>
          <input
            type="text"
            id="phone_number"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pe-10 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
            placeholder="99 99 99 99"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="email_address" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Adresse email*</label>
          <input
            type="text"
            id="email_address"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pe-10 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
            placeholder="hello@example.com"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
  
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="phone_number" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Numéro de téléphone 2: (optionnel)</label>
          <input
            type="text"
            id="second_phone_number"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pe-10 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
            placeholder="99 99 99 99"
            value={secondPhoneNumber}
            onChange={(e) => setSecondPhoneNumber(e.target.value)}
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="ville" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Gouvernorat*</label>
          <select
            id="governorate_id"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
            value={governorate.governorates_id || '-1'}
            onChange={(e) => {handleSetGovernorate(e.target.value);handleAdd('governorate', e.target.value)}}
            required
          >
            <option value="-1">Sélectionnez le gouvernorat</option>
            {governorates.map((governorate)=>(
              <option value={governorate.governorates_id}>{governorate.name}</option>
            ))}            
          </select>
        </div>

        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="delegation" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Délégation*</label>
          <select
          
          disabled={delegationsStatus}
          
            id="delegation"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
            value={delegation.delegations_id || '-1'}
            onChange={(e) => {handleSetDelegation(e.target.value);handleAdd('delegation', e.target.value);}}
            required
          >
            <option value="-1">Sélectionner la délégation</option>
            {delegationsFiltred.map((delegation)=>(
              <option value={delegation.delegations_id}>{delegation.name}</option>
            ))}            
          </select>
        </div>

        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="city" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Zone*</label>
          <select
            disabled={sectorsStatus}
            id="Sector"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
            value={sector.sectors_id || '-1'}
            onChange={(e) => {handleSetSector(e.target.value)}}
            required
          >
            <option value="-1">Sélectionner une zone</option>
            {sectorsFiltred.map((sector)=>(
              <option value={sector.sectors_id}>{sector.name}</option>
            ))} 
          </select>
        </div>

        <div className="w-full ">
          <label htmlFor="Address" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Addresse*</label>
          <input
            type="text"
            id="Address"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
      </div>

      <button type="submit" className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
      Confirmer la commande
      </button>
    </form>

            </div>


          </section>

          <script src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/datepicker.min.js"></script>

        </div>
      </DragCloseDrawer>
    </div>
  )
}



const itemUI = (item, addQte, decQte, removeProduct, index) => (
  <div key={index} className="rounded-lg border border-gray-300 bg-white-plus   p-4 shadow-sm   md:p-6">
    <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
      <a href="0" className="shrink-0 md:order-1">
        <img className="h-20 w-20 object-cover" src={"https://docuverse.tn/public/SiteImages/products/"+ item.main_pic_link} alt={`${item.name}`} />
      </a>

      <label for="counter-input" className="sr-only">Choose quantity:</label>
      <div className="flex items-center justify-between md:order-3 md:justify-end">
        <div className="flex items-center">
          <button type="button" id="decrement-button" onClick={() => { decQte(item.id) }} data-input-counter-decrement="counter-input" className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <svg className="h-2.5 w-2.5  text-black-plus  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
            </svg>
          </button>
          <input type="text" id="counter-input" data-input-counter className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium  text-black-plus focus:outline-none focus:ring-0  " placeholder="" value={item.qte} required />
          <button type="button" id="increment-button" onClick={() => { addQte(item.id) }} data-input-counter-increment="counter-input" className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
            <svg className="h-2.5 w-2.5  text-black-plus  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
            </svg>
          </button>
        </div>
        <div className="text-end md:order-4 md:w-32">
          <p className="text-base font-bold  text-black-plus  ">{item.price} TND</p>
        </div>
      </div>

      <div className="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
        <a href="0" className="text-base font-medium  text-black-plus hover:underline  ">{item.name}</a>

        <div className="flex items-center gap-4">


          <button type="button" onClick={() => { removeProduct(item.id) }} className="inline-flex items-center text-sm font-medium text-red-600 hover:underline dark:text-red-500">
            <svg className="me-1.5 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6" />
            </svg>
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
)

