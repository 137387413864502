import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { CarouselCustomNavigation } from '../../../Components/Carousel';
 import Footer from '../../../Components/footer/Footer';
import LayoutMain from '../../LayoutMain';
import InfoBar from '../../../Components/header/info-bar/InfoBar';
import ShuffleHero from '../../../Components/header/shuffle-hero/ShuffleHero';
import { useParams, useSearchParams } from 'react-router-dom';

 

const Filterconfig = [
  {
    filterLabel: "Categorie",
    FilterOptions: ['Concours', 'Résumés & TDs corrigés', 'Pack']
  },
  {
    filterLabel: "Section",
    FilterOptions: ['MP', 'PC', 'PT']
  },
  {
    filterLabel: "Niveau",
    FilterOptions: ['1ere', '2ème']
  },
]

function Products() {
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedCategorie, setSelectedCategorie] = useState('');
  const [showSection, setShowSection] = useState(true);
  const [showLevel, setShowLevel] = useState(true);
  const [showCategorie, setShowCategorie] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8;
  const [products, setProducts] = useState([]);
  const [searchParams, setSearchParams]  = useSearchParams();
  useEffect(()=>{
    const section = searchParams.get("section");
    if(section){
      setSelectedSection(section.toUpperCase())
    }
  }, [])


  const loadProducts = async () => {
    try {
      const response = await fetch('https://docuverse.tn/api/products', {
        method: 'GET', // Specify the HTTP method
        headers: {
          'Content-Type': 'application/json', 
        }});
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleLevelChange = (level) => {
    if(selectedLevel!==level){
      setSelectedLevel(level);
    }else{
      setSelectedLevel("")  
    }
  };

  const handleSectionChange = (section) => {
    if(selectedSection!==section){
      setSelectedSection(section);
    }else{
      setSelectedSection("");
    }
  };

  const handleCategorieChange = (categorie) => {
    if(selectedCategorie!==categorie){
      setSelectedCategorie(categorie);
    }else{
      setSelectedCategorie("");
    }
  };

  const toggleMenu = (Filter) => {
    switch (Filter) {
      case "Section":
        setShowSection(!showSection)
        break;
      case "Categorie":
        setShowCategorie(!showCategorie)
        break;
      default:
        setShowLevel(!showLevel)
        break;
    }
 
  }
 
  useEffect(()=>{
    loadProducts();
  }, [])

  const handleClearFilters = () => {
    setSelectedLevel('');
    setSelectedSection('');
    setSelectedCategorie('');
  };

  const filteredProducts = products.filter(product => {
    return (
      (!selectedLevel || product.level.name === selectedLevel) &&
      (!selectedSection || product.section.name === selectedSection) &&
      (!selectedCategorie || product.categorie.name === selectedCategorie) 
    );
  });

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const displayedProducts = filteredProducts.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);

  return (
    <LayoutMain >





      <ShuffleHero home={false} title={'Boutique'} subTitle={'Voir tous les produits de Docuverse'} />

      <div className='max-w-6xl m-auto -mt-16'>
        <CarouselCustomNavigation images={[{ url: "/banner1.png", alt: "img" }, { url: "/banner2.png", alt: "img" }, { url: "/banner3.png", alt: "img" }]} />
      </div>
      <div className="min-h-screen  mt-20">


        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row">
            {/* Filters */}
            <aside className="w-full lg:w-1/4 bg-transparent p-6  mb-6 lg:mb-0 select-none">
              <h2 className="text-2xl font-monta text-black-plus font-semibold mb-6">Options de filtres</h2>
              <div className="mb-6">
                {Filterconfig.map(Filter => (
                  <div className="mb-6 border-2 border-gray-300 p-4 rounded-xl " onClick={() => { toggleMenu(Filter.filterLabel) }}>
                    <div className='flex justify-between items-center' >
                      <h3 className="font-monta text-black-plus text-lg mb-2">{Filter.filterLabel}</h3>
                      {((Filter.filterLabel === "Section") ? showSection : showLevel) === true ? <FaArrowUp /> : <FaArrowDown />}
                    </div>

                    <div className={`flex flex-col space-y-2 ${((Filter.filterLabel === "Section") ? showSection : ((Filter.filterLabel === "Categorie")?showCategorie:showLevel)) === true ? "" : 'hidden'}`}>
                      {Filter.FilterOptions.map(option => (
                        <label
                          className={`p-2 font-monta font-normal rounded-xl border-2 ${((Filter.filterLabel === "Section") ? selectedSection : ((Filter.filterLabel === "Categorie")? selectedCategorie:selectedLevel)) === option ? ' text-C1 bg-orange-50 accent-orange-500 ring-orange-500' : 'border-gray-300 accent-transparent'}`}
                        >
                          <input
                            type="radio" text-C1
                            name={Filter.filterLabel}
                            className="mr-2 accent-orange-500 ring-orange-500"
                            onClick={() => {
                              ((Filter.filterLabel === "Section") ? handleSectionChange(option) : ((Filter.filterLabel === "Categorie")? handleCategorieChange(option) : handleLevelChange(option)))
                            }}
                            checked={((Filter.filterLabel === "Section") ? selectedSection : ((Filter.filterLabel === "Categorie") ? selectedCategorie :selectedLevel)) === option}
                          />

                          {option}
                        </label>
                      ))}
                    </div>
                  </div>
                ))}

                <div className="text-center">
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
                    onClick={handleClearFilters}
                  >
                    Effacer les filtres
                  </button>
                </div>
              </div>
            </aside>

            {/* Products */}
            <main className="w-full lg:w-3/4 lg:pl-6">
              <div className='w-full flex justify-between items-center pb-4'>
                <h3 className='font-monta font-medium text-sm text-black-plus'>{displayedProducts.length} Documents</h3>
                <h3 className='font-monta font-medium text-sm text-black-plus'>Trier par : Alpha</h3>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {displayedProducts.map(product => (
                  <motion.a
                    key={product.id}
                    className="bg-white rounded-lg shadow-lg overflow-hidden w-52"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    href={"products/" +product.products_id}
                  >
                    <div className="p-4">
                      <img src={"https://docuverse.tn/public/SiteImages/products/"+ product.main_pic_link} alt={product.name} className=" object-cover w-52 h-64" />
                      <div className="mt-2">
                        <h3 className="text-lg font-semibold">{product.name}</h3>
                        <p className="text-gray-600">
                        {(product.promo_price != null && product.promo_price !== 0) ? (
                                  <>
                                    <span className="line-through">{product.price} TND</span> {product.promo_price} TND
                                  </>
                                ) : (
                                  `${product.price} TND`
                                )}
                          </p>
                      {/*    <p className={product.inStock ? "text-green-600" : "text-red-600"}>
                          {product.inStock ? "In stock" : "Out of stock"}
                        </p>*/}
                      </div>
                    </div>
                  </motion.a>
                ))}
              </div>
            </main>
          </div>
        </div>

        {/* Pagination */}
        <div className="text-center py-6">
          <div className="inline-flex items-center">
            <button
              className={`px-4 py-2 mx-1 rounded ${currentPage === 1 ? 'bg-gray-300' : 'bg-C1/80 text-white'}`}
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Précédente
            </button>
            <span className="px-4 py-2 mx-1 text-lg font-monta">{currentPage} of {totalPages}</span>
            <button
              className={`px-4 py-2 mx-1 rounded ${currentPage === totalPages ? 'bg-gray-300' : 'bg-C1/80 text-white'}`}
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Suivante
            </button>
          </div>
        </div>
      </div>


      <Footer />





    </LayoutMain >


  );
}

export default Products;
